<template>
  <div class="card col-12 col-md-4 py-2 login-font">
    <h3>Reset password</h3>
    <!--begin::Form-->
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div
        role="alert"
        v-bind:class="{ show: errors.length }"
        class="alert fade alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>

      <b-form-group
        class="login-font"
        label="New password"
        label-for="txtPassword"
      >
        <b-form-input
          class="form-control login-font"
          type="password"
          id="txtPassword"
          name="txtPassword"
          v-model="$v.form.password.$model"
          :state="validateState('password')"
          aria-describedby="p-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="p-live-feedback">
          Password is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="login-font"
        label="Confirm password"
        label-for="txtRPassword"
      >
        <b-form-input
          class="form-control login-font"
          type="password"
          id="txtRPassword"
          name="txtRPassword"
          v-model="$v.form.repeatPassword.$model"
          :state="validateState('repeatPassword')"
          aria-describedby="rp-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="rp-live-feedback">
          Passwords must be identical.
        </b-form-invalid-feedback>
      </b-form-group>
      <!--begin::Action-->
      <div
        class="form-group d-flex flex-wrap justify-content-between align-items-center"
      >
        <button class="btn btn-primary login-font">
          SUBMIT
        </button>
        <button class="btn btn-link login-font" @click="goToLogin">
          Back to LOGIN
        </button>
      </div>
      <!--end::Action-->
    </b-form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGOUT } from "@/store/auth.module";
import ApiService from "@/services/api.service";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
//import jwtService from "@/services/jwt.service";
export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        password: null,
        repeatPassword: null
      }
    };
  },
  validations: {
    form: {
      password: {
        required
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        repeatPassword: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;
      const id = this.$store.state.auth.user.id;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // dummy delay
      setTimeout(() => {
        // send login request
        ApiService.put("reset-password", { password, id }).then(({ data }) => {
          this.$router.push({ name: "Login" });
        });
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
<style lang="scss">
.card .form-control,
.btn {
  opacity: 0.9;
}
.card {
  background-color: #000;
}
.login-font {
  font-size: 12px !important;
  color: #fdfdfd;
}
</style>
